import { render, staticRenderFns } from "./NeoNoScrollWrap.vue?vue&type=template&id=2badf78c&scoped=true&"
import script from "./NeoNoScrollWrap.vue?vue&type=script&lang=js&"
export * from "./NeoNoScrollWrap.vue?vue&type=script&lang=js&"
import style0 from "./NeoNoScrollWrap.vue?vue&type=style&index=0&id=2badf78c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2badf78c",
  null
  
)

export default component.exports