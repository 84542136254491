<!--
 * @Author: gaojingran
 * @Date: 2021-03-29 17:41:00
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-04-13 16:14:08
 * @Description: 隐藏默认滚动条
-->

<style lang="less" scoped>
.outer-container {
  overflow: hidden;
  position: relative;
  .inner-container {
    position: absolute;
    top: 0;
    left: 0;
    overflow: scroll;
  }
}
</style>

<template>
  <div class="outer-container">
    <div class="inner-container" ref="container" :style="{ right: -scrollWidth + 'px', bottom: -scrollWidth + 'px' }">
      <slot></slot>
    </div>
    <slot name="outer" />
  </div>
</template>

<script>
import { getScrollBarSize } from '@/utils/utils'

export default {
  name: 'NeoNoScrollWrap',
  data() {
    return {
      scrollWidth: getScrollBarSize(),
    }
  },
  methods: {
    getScrollWrapper() {
      return this.$refs.container
    },
  },
}
</script>
